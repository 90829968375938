import { Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styles from './FaqAccordions.module.scss';

export default function FaqAccordions(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles['faq-accordions-wrapper']} id='faq-accordions'>
            <Container maxWidth="lg" className={styles['faq-accordions-container']}>
                <div className={styles['faq-accordions']}>
                    <h3 className={styles['faq-accordions-title']}>{t('faq.title')}</h3>

                    {Array.from({length:6}).map((value, index) => {
                        return (
                            <Accordion className={styles['accordion']} key={index}>
                                <AccordionSummary id={`panel-header-${index}`} aria-controls={`panel-content-${index}`} className={styles['accordion-header']}>
                                    {t(`faq.question${index+1}`)}
                                </AccordionSummary>
                                <AccordionDetails className={styles['accordion-content']}>
                                    <div className={styles['accordion-content-column']}>
                                        <div className={styles['accordion-content-column-content']}>
                                            <Trans i18nKey={`faq.answer${index+1}`}/>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                     })}

                </div>
            </Container>
        </div>
    );
}