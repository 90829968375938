import { Button, ButtonBase, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Slider, Container, FormHelperText } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import PriceInput from '../PriceInput';
import styles from './CalculatorQuoteColumn.module.scss';

export default function CalculatorQuoteColumn(props) {
    const {
        planSlug,
        sliderInputKey,
        onSliderInputChange,
        coverageAmount,
        onSliderChange,
        coverageMin,
        coverageMax,
        coverageStep,
        calculatedMonthlyRate,
        applyUrl,
        brochureUrl,
        canCalculateMonthlyCost} = props;
    const { t, i18n } = useTranslation();

    const sliderLabelID = `slider-label-${planSlug}`;

    return (
        <div className={styles['calculator-quotes-column']}>
            <div className={styles['calculator-quotes-column-top']}>
                <div className={styles['calculator-quotes-column-header']}>
                    <Trans i18nKey={`calculatorQuiz.quotes.${planSlug}.header`}/>
                </div>
                <div className={styles['calculator-quotes-column-content']}>
                    <Trans i18nKey={`calculatorQuiz.quotes.${planSlug}.content`}/>
                </div>
            </div>
            <div className={styles['calculator-quotes-column-slider']}>
                <div className={styles['calculator-quotes-column-slider-label']} id={sliderLabelID}>
                    {t('calculatorQuiz.quotes.slideToSelect')}
                </div>
                <TextField
                    key={sliderInputKey}
                    onBlur={onSliderInputChange}
                    onKeyUp={onSliderInputChange}
                    value={coverageAmount}
                    name={`coverage_amount_${planSlug}`}
                    InputProps={{inputComponent: PriceInput, inputProps: {"aria-labelledby": sliderLabelID}}}
                    className={styles['calculator-quotes-column-slider-input']}/>
                <Slider
                    aria-labelledby={sliderLabelID}
                    onChange={onSliderChange}
                    value={coverageAmount}
                    defaultValue={coverageMin}
                    className={styles['calculator-quotes-column-slider-control']}
                    valueLabelDisplay="off"
                    step={coverageStep}
                    min={coverageMin}
                    max={coverageMax}
                    color="secondary"
                    />
                <div className={styles['calculator-quotes-column-slider-marks']}>
                    <div className={styles['calculator-quotes-column-slider-marks-min']}>
                        Min
                        <br/>
                        {
                            new Intl.NumberFormat((i18n.resolvedLanguage === "fr" ? "fr-CA" : "en-CA"), {
                                style: 'currency',
                                currency: 'CAD',
                                maximumFractionDigits: 0,
                            }).format(coverageMin)
                        }
                    </div>
                    <div className={styles['calculator-quotes-column-slider-marks-max']}>
                        Max
                        <br/>
                        {
                            new Intl.NumberFormat((i18n.resolvedLanguage === "fr" ? "fr-CA" : "en-CA"), {
                                style: 'currency',
                                currency: 'CAD',
                                maximumFractionDigits: 0,
                            }).format(coverageMax)
                        }
                    </div>
                </div>
            </div>

            <div className={styles['calculator-quotes-column-results']}>
                <div className={styles['calculator-quotes-column-results-title']}>
                    {t('calculatorQuiz.quotes.yourMonthlyCost')}
                </div>
                <div className={styles['calculator-quotes-column-results-price'] + ' ' + (canCalculateMonthlyCost ? 'has-value' : '')}>
                    {i18n.resolvedLanguage === 'en' && <sup>$</sup>}
                    { canCalculateMonthlyCost ?
                        new Intl.NumberFormat((i18n.resolvedLanguage === "fr" ? "fr-CA" : "en-CA"), {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(calculatedMonthlyRate)
                        : (i18n.resolvedLanguage === "fr" ? "0,00" : "0.00")}
                    {i18n.resolvedLanguage === 'fr' && <sup>$</sup>}
                </div>
                <div className={styles['calculator-quotes-column-results-priceunderline']}>
                    {t('calculatorQuiz.quotes.beforeApplicableTaxes')}
                </div>
                <div className={styles['calculator-quotes-column-results-coverage']}>
                    {t('calculatorQuiz.quotes.yourCoverageAmount')}
                    <br/>
                    <strong>
                        {
                            new Intl.NumberFormat((i18n.resolvedLanguage === "fr" ? "fr-CA" : "en-CA"), {
                                style: 'currency',
                                currency: 'CAD',
                                maximumFractionDigits: 0,
                            }).format(coverageAmount)
                        }
                    </strong>
                </div>

                <Button disabled={!canCalculateMonthlyCost} className={styles['calculator-quotes-column-results-apply']} variant="contained" href={applyUrl} target="_blank" rel="noopener" data-gtm-link-event-name={`click_plan_callout_apply_${planSlug}`}>{t(`calculatorQuiz.quotes.${planSlug}.apply`)}</Button>
                <br/>
                <ButtonBase className={styles['calculator-quotes-column-results-brochure']} href={brochureUrl} target="_blank" rel="noopener" data-gtm-link-event-name={`click_plan_callout_brochure_${planSlug}`}><Trans i18nKey={`calculatorQuiz.quotes.${planSlug}.viewBrochure`}/></ButtonBase>
            </div>
        </div>
    );
}