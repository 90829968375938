import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        preload: ['en', 'fr'],
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'span', 'i', 'p', 'em', 'small', 'sup', 'ul', 'li', 'ol']
        }
    });

export default i18n;