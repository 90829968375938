import { Grid, ButtonBase, Typography, Link, Button, Menu, MenuItem, Container } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SchoolsContext } from "../../contexts/SchoolsContext";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ManulifeLogoEN } from '../../images/logo-manulife-white-en.svg';
import { ReactComponent as ManulifeLogoFR } from '../../images/logo-manulife-white-fr.svg';
import { ReactComponent as HamburgerIcon } from '../../images/icon-hamburger.svg';
import styles from './Header.module.scss';
import pushEventToDataLayer from '../../helpers/gtm';


export default function Header(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { currentSchoolSlug } = useContext(SchoolsContext);
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLangClick = (event) => {
        if (currentSchoolSlug !== null) {
            // navigate to english if french and vice versa.
            pushEventToDataLayer('click_language_selector');
            const path = i18n.resolvedLanguage === "fr" ? `/${currentSchoolSlug}` : `/${currentSchoolSlug}/fr`;
            navigate(path);
        }
    };

    const handleResize = () => {
        setAnchorEl(null);
        setIsClicked(false);
    }

    const handleClick = (event) => {
        setIsClicked(!isClicked);

        if (!isClicked) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuLinkClick = (event) => {
        const hash = event.target.href.split('#')[1];

        pushEventToDataLayer('click_quicklink', {
            quicklink_hash: hash
        });

        setAnchorEl(null);
    };

    return (
        <header className={styles['header']} id="header">
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Link href={i18n.resolvedLanguage === "fr" ? "https://manuvie.ca" : "https://manulife.ca"} target="_blank" rel="noopener" className={styles['header-logo']} aria-label={t('wrapper.header.srLogo')} data-gtm-link-event-name="click_header_logo">
                            {
                                i18n.resolvedLanguage === "fr" ? <ManulifeLogoFR className={styles['header-logo-image']}/> : <ManulifeLogoEN className={styles['header-logo-image']}/>
                            }
                        </Link>
                    </Grid>

                    <Grid item>
                        <ButtonBase
                            onClick={handleLangClick}
                            className={styles['header-lang']}>
                                { i18n.resolvedLanguage === "en" ? "FR" : "EN" }
                        </ButtonBase>

                        {
                            !isLarge ? <ButtonBase
                                className={styles['header-toggle']}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>
                                    <Typography style={visuallyHidden}>{t('wrapper.srOpenMobileNavigation')}</Typography>
                                    <HamburgerIcon className={styles['header-toggle-icon']}/>
                                    <Menu
                                        className={styles['header-toggle-menu']}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            style: {
                                                padding: 0,
                                            },
                                        }}
                                        PaperProps={{
                                            style: {
                                            left: '0',
                                            transform: 'translateX(16px) translateY(15px)',
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                            maxWidth: '90vw',
                                            }
                                        }}
                                    >
                                        <MenuItem onClick={handleMenuLinkClick}>
                                            <a href='#term-life-calculator'>{t('quickLinks.menuLabel3')}</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuLinkClick}>
                                            <a href='#term-life-intro'>{t('quickLinks.menuLabel1')}</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuLinkClick}>
                                            <a href='#term-life-plans'>{t('quickLinks.menuLabel2')}</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuLinkClick}>
                                            <a href='#faq-accordions'>{t('quickLinks.menuLabel4')}</a>
                                        </MenuItem>
                                    </Menu>
                            </ButtonBase> : null
                        }

                    </Grid>
                </Grid>
            </Container>
        </header>
    )
}