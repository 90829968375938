import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatBase } from 'react-number-format';

const PriceInput = forwardRef(function PriceInput(props, ref) {
    const { onChange, onBlur, onKeyUp, ...other } = props;
    const { t, i18n } = useTranslation();
    const format = (numStr) => {
        if (numStr === '') return '';
        const culture = i18n.resolvedLanguage === 'fr' ? 'fr-CA' : 'en-CA';

        return new Intl.NumberFormat(culture, {
            maximumFractionDigits: 0,
        }).format(numStr);
    }

    return (
        <NumberFormatBase
            {...other}
            format={format}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            valueIsNumericString
        />
    );
});

export default PriceInput;