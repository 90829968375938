import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SchoolsContext } from '../../contexts/SchoolsContext';
import NotFoundPage from '../NotFoundPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Masthead from '../../components/Masthead/Masthead';
import QuickLinks from '../../components/QuickLinks/QuickLinks';
import IntroductoryContent from '../../components/IntroductoryContent/IntroductoryContent';
import ProductCallouts from '../../components/ProductCallouts/ProductCallouts';
import ApplicationCallout from '../../components/ApplicationCallout/ApplicationCallout';
import FaqAccordions from '../../components/FaqAccordions/FaqAccordions';
import Calculator from '../../components/Calculator/Calculator';
import SchoolSelectorModal from '../../components/SchoolSelectorModal/SchoolSelectorModal';
import styles from './MainPage.module.scss';


export default function MainPage() {
    // check if the school slug is real
    const params = useParams();
    const { setCurrentSchoolSlug } = useContext(SchoolsContext);

    useEffect(() => {
        if (params.hasOwnProperty('schoolslug')) {
            setCurrentSchoolSlug(params.schoolslug);
        }
    }, []);

    return (
        <div className={styles['wrapper']}>
            <Header/>

            <main className={styles['main']}>
                <Masthead/>
                <QuickLinks/>
                <Calculator/>
                <IntroductoryContent/>
                <ProductCallouts/>
                <ApplicationCallout/>
                <FaqAccordions/>
            </main>

            <Footer/>

            <SchoolSelectorModal isOpen={!params.hasOwnProperty('schoolslug')}/>
        </div>
    );
}