import { Container } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styles from './IntroductoryContent.module.scss';

export default function IntroductoryContent(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles['intro-content']} id="term-life-intro">
            <Container maxWidth="lg">
                <Trans i18nKey="introductoryContent.intro" />
            </Container>
        </div>
    );
}