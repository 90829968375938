import { useState } from "react";

// Global schools data.
import schoolsData from '../data/schools.json';

export default function useSchool() {
    const [currentSchoolSlug, setCurrentSchoolSlug] = useState(null);

    return {
        schoolsData: schoolsData,
        currentSchoolSlug,
        setCurrentSchoolSlug: setCurrentSchoolSlug
    }
}