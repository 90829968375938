import { BrowserRouter} from "react-router-dom";
import { AppRoutes } from "./routes/routes";
import LoaderBackdrop from './components/LoaderBackdrop';
import EntryPage from './pages/EntryPage';
import { SchoolsContext } from "./contexts/SchoolsContext";
import useSchool from "./hooks/useSchool";

export default function App() {
    const {schoolsData, currentSchoolSlug, setCurrentSchoolSlug} = useSchool();

    return (
        <SchoolsContext.Provider value={
            {
                schoolsData,
                currentSchoolSlug,
                setCurrentSchoolSlug
            }
        }>
            <BrowserRouter children={AppRoutes}>
            </BrowserRouter>
        </SchoolsContext.Provider>
    );
}