import { Tooltip, ButtonBase, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as WhiteCloseIcon } from '../../images/icon-close-white.svg';
import styles from './CalculatorTooltip.module.scss';
import { useState } from 'react';

export default function CalculatorTooltip(props) {
    const { tooltipTextKey, ...other } = props;
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleToggle = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    return (
        <Tooltip
            {...other}
            arrow
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            placement="bottom-end"
            PopperProps={{
                disablePortal: true,
                className: styles['calculator-tooltip-popper']
            }}
            disableFocusListener
            disableHoverListener
            leaveDelay={2000}
            title={
                <div className={styles['calculator-tooltip-inner']}>
                    <ButtonBase className={styles['calculator-tooltip-inner-close']} onClick={handleClose}>
                        <Typography style={visuallyHidden}>{t('calculatorQuiz.tooltipCloseLabel')}</Typography>
                        <WhiteCloseIcon className={styles['calculator-tooltip-inner-close-icon']}/>
                    </ButtonBase>
                    <Trans i18nKey={tooltipTextKey}/>
                </div>
            }>
                <ButtonBase className={styles['calculator-tooltip-button'] + ' ' + (open ? 'open' : '')} onClick={handleToggle}>
                    <Typography style={visuallyHidden}>{t('calculatorQuiz.tooltipOpenLabel')}</Typography>
                </ButtonBase>
        </Tooltip>
    );
}
