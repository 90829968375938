import { Routes, Route, useParams } from "react-router-dom";
import EntryPage from "../pages/EntryPage";
import MainPage from "../pages/MainPage/MainPage";
import LanguagePath from "../components/LanguagePath";
import NotFoundPage from "../pages/NotFoundPage";
import useSchool from "../hooks/useSchool";

const ValidateMainPage = () => {
    const params = useParams();
    const { schoolsData, setCurrentSchoolSlug } = useSchool();

    if (params.hasOwnProperty('schoolslug') && !schoolsData.hasOwnProperty(params.schoolslug)) {
        return <NotFoundPage/>;
    }

    return <MainPage/>;
};

export const AppRoutes = (
    <Routes>
        <Route path="/" element={<LanguagePath lang="en"/>}>
            <Route index element={<ValidateMainPage/>}/>
        </Route>

        <Route path="/fr" element={<LanguagePath lang="fr"/>}>
            <Route index element={<ValidateMainPage/>}/>
        </Route>

        <Route path="/:schoolslug" element={<LanguagePath lang="en"/>}>
            <Route index element={<ValidateMainPage/>}/>
        </Route>

        <Route path="/:schoolslug/fr" element={<LanguagePath lang="fr"/>}>
            <Route index element={<ValidateMainPage/>}/>
        </Route>

        <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
);