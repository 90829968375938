import { useEffect, useLayoutEffect } from 'react';
import { useLocation, pathna } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export default function LanguagePath({lang}) {
    const { i18n, t } = useTranslation();
    const location = useLocation();

    document.documentElement.lang = i18n.resolvedLanguage;
    document.title = t('htmlTitle');

    useEffect(() => {
        if (lang && i18n.resolvedLanguage !== lang) {
            i18n.changeLanguage(lang);
        }
    }, [location]);

    return <Outlet/>
}