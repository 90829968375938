// AGE BANDS: array indexes
// 0: 18 to 29
// 1: 30 to 34
// 2: 35 to 39
// 3: 40 to 44
// 4: 45 to 49
// 5: 50 to 54
// 6: 55 to 60
// 7: 61 to 64
// 8: 65 to 69
// 9: 70 to 74
// 10: 75 to 79
// 11: 80 to 84

// COVERAGE BANDS: object keys
// regular: below $280,000
// discounted: $280,000 or more

const termLifeRates = {
    regular: {
        male: {
            nonsmoker: [2.45, 2.55, 3.25, 4.9, 7.5, 11.25, 18.5, 25.9, 40.4, 78.4, 142.7, 251.15],
            smoker: [3.5, 4.35, 5.95, 9.75, 14.75, 21.75, 32.5, 53.65, 93.9, 167.15, 259.1, 357.55]
        },
        female: {
            nonsmoker: [1.75, 2, 2.4, 3.8, 5.45, 8, 13, 18.45, 28.78, 55.25, 101.1, 176.95],
            smoker: [2.75, 3.25, 4.1, 6.8, 9.85, 14.1, 22.5, 33.3, 49.95, 92.4, 158.95, 254.3]
        }
    },
    discounted: {
        male: {
            nonsmoker: [2.205, 2.295, 2.925, 4.41, 6.75, 10.125, 16.65, 23.31, 36.36, 70.56, 128.43, 226.035],
            smoker: [3.15, 3.915, 5.355, 8.775, 13.275, 19.575, 29.25, 48.285, 84.51, 150.435, 233.19, 321.795]
        },
        female: {
            nonsmoker: [1.575, 1.8, 2.16, 3.42, 4.905, 7.2, 11.7, 16.605, 25.902, 49.725, 90.99, 159.255],
            smoker: [2.475, 2.925, 3.69, 6.12, 8.865, 12.69, 20.25, 29.97, 44.955, 83.16, 143.055, 228.87]
        }
    }
};

function getAgeIndex(age) {
    if (age >= 18 && age <= 29) {
        return 0;
    } else if (age >=30 && age <= 34) {
        return 1;
    } else if (age >= 35 && age <= 39) {
        return 2;
    } else if (age >= 40 && age <= 44) {
        return 3;
    } else if (age >= 45 && age <= 49) {
        return 4;
    } else if (age >= 50 && age <= 54) {
        return 5;
    } else if (age >= 55 && age <= 60) {
        return 6;
    } else if (age >= 61 && age <= 64) {
        return 7;
    } else if (age >= 65 && age <= 69) {
        return 8;
    } else if (age >= 70 && age <= 74) {
        return 9;
    } else if (age >= 75 && age <= 79) {
        return 10;
    } else if (age >= 80 && age <= 84) {
        return 11;
    }

    return null;
}

export default function getTermLifeMonthlyRate(age, gender, isSmoker, coverageAmount) {
    if (age === '' || gender === null || (gender !== 'female' && gender !== 'male') || isSmoker === null || coverageAmount <= 0 || coverageAmount % 35000 !== 0) return null;

    const ageIndex = getAgeIndex(parseInt(age, 10));
    if (ageIndex === null) return null;

    const coverageBand = coverageAmount < 280000 ? 'regular' : 'discounted';
    const smokerBand = isSmoker === 'true' ? 'smoker' : 'nonsmoker';

    return (coverageAmount / 35000) * termLifeRates[coverageBand][gender][smokerBand][ageIndex];
}