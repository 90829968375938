import { useState, useEffect, useRef, useCallback } from 'react';
import { Container } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styles from './QuickLinks.module.scss';
import pushEventToDataLayer from '../../helpers/gtm';
import useThrottledOnScroll from '../../hooks/useThrottledOnScroll';

export default function ApplicationCallout(props) {
    const mq = window.matchMedia('(min-width: 1024px)');
    const { t, i18n } = useTranslation();
    const [isFixed, setIsFixed] = useState(false);
    const [activeState, setActiveState] = useState(null);
    const items = [
        {hash: 'term-life-calculator', node: document.getElementById('term-life-calculator')},
        {hash: 'term-life-intro', node: document.getElementById('term-life-intro')},
        {hash: 'term-life-plans', node: document.getElementById('term-life-plans')},
        {hash: 'faq-accordions', node: document.getElementById('faq-accordions')}
    ];

    const itemsWithNodeRef = useRef([]);
    useEffect(() => {
        itemsWithNodeRef.current = items;
    }, [items]);

    const clickedRef = useRef(false);
    const unsetClickedRef = useRef(null);

    const findActiveIndex = useCallback(() => {
        // Set Navigation fixed position
        if (window.scrollY >= 425 && mq.matches) {
            document.body.classList.add('quicklinks-fixed');
            setIsFixed(true);
        }
        else {
            document.body.classList.remove('quicklinks-fixed');
            setIsFixed(false);
        }

        // Don't set the active index based on scroll if a link was just clicked
        if (clickedRef.current) {
            return;
        }

        let active;
        for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i--) {
            // No hash if we're near the top of the page
            if (document.documentElement.scrollTop < 200) {
                active = { hash: null };
                break;
            }

            const item = itemsWithNodeRef.current[i];
            if (item.node && item.node.offsetTop < document.documentElement.scrollTop + document.documentElement.clientHeight / 8) {
                active = item;
                break;
            }
        }

        if (active && activeState !== active.hash) {
            setActiveState(active.hash);
        }
    }, [activeState]);

    useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166);

    const handleClick = (event) => {
        const hash = event.target.href.split('#')[1];

        pushEventToDataLayer('click_quicklink', {
            quicklink_hash: hash
        });

        // Ignore click for new tab/new window behavior
        if (
            event.defaultPrevented ||
            event.button !== 0 || // ignore everything but left-click
            event.metaKey ||
            event.ctrlKey ||
            event.altKey ||
            event.shiftKey
        ) {
            return;
        }

        // Used to disable findActiveIndex if the page scrolls due to a click
        clickedRef.current = true;
        unsetClickedRef.current = setTimeout(() => {
            clickedRef.current = false;
        }, 1000);

        if (activeState !== hash) {
            setActiveState(hash);
        }
    };

    useEffect(() => () => {
        clearTimeout(unsetClickedRef.current);
    }, []);

    return (
        <div className={styles['quick-links-wrapper']} id='overview'>
            <Container maxWidth="lg" className={`${styles['quick-links-container']} ${isFixed ? styles['fixed'] : ''}`}>
                <div id="quicklinksbar" className={`${styles['quick-links']} ${isFixed ? styles['fixed'] : ''}`}>
                    <div className={`${styles['quick-links-title']} ${isFixed ? styles['fixed'] : ''}`}>{t('quickLinks.title')}</div>
                    <ul className={`${styles['quick-links-nav']} ${isFixed ? styles['fixed'] : ''}`}>
                        <li>
                            <a href='#term-life-calculator' className={`${activeState === 'term-life-calculator' ? styles['active'] : ''}`} onClick={handleClick}>
                                {t('quickLinks.menuLabel3')}
                            </a>
                        </li>
                        <li>
                            <a href='#term-life-intro' className={`${activeState === 'term-life-intro' ? styles['active'] : ''}`} onClick={handleClick}>
                                {t('quickLinks.menuLabel1')}
                            </a>
                        </li>
                        <li>
                            <a href='#term-life-plans' className={`${activeState === 'term-life-plans' ? styles['active'] : ''}`} onClick={handleClick}>
                                {t('quickLinks.menuLabel2')}
                            </a>
                        </li>

                        <li>
                            <a href='#faq-accordions' className={`${activeState === 'faq-accordions' ? styles['active'] : ''}`} onClick={handleClick}>
                                {t('quickLinks.menuLabel4')}
                            </a>
                        </li>
                    </ul>
                </div>
            </Container>
        </div>
    );
}