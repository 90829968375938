import { Grid, ButtonBase, Typography, Link, Container } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ManulifeLogoEN } from '../../images/logo-manulife-white-footer-en.svg';
import { ReactComponent as ManulifeLogoFR } from '../../images/logo-manulife-white-footer-fr.svg';
import pushEventToDataLayer from '../../helpers/gtm';
import styles from './Footer.module.scss';

export default function Footer(props) {
    const { t, i18n } = useTranslation();

    const contestLink = <Link data-gtm-link-event-name="click_contest_rules" href={i18n.resolvedLanguage === "fr" ? "/pdf/2024 Alumni Term Life Contest Rules - French.pdf" : "/pdf/2024 Alumni Term Life Contest Rules - English.pdf"} target="_blank" rel="noopener">dummyLabel</Link>;
    const accessibleLink = <Link data-gtm-link-event-name="click_accessibility_info" href={i18n.resolvedLanguage === "fr" ? "https://manuvie.ca/accessibilite" : "https://manulife.ca/accessibility"} target="_blank" rel="noopener">dummyLabel</Link>;
    const privacyPolicyLink = <Link data-gtm-link-event-name="click_privacy_policy" href={i18n.resolvedLanguage === "fr" ? "https://www.manuvie.ca/politiques-de-confidentialite/enonce-de-confidentialite-division-canadienne.html" : "https://www.manulife.ca/privacy-policies/canadian-division-privacy-policy.html"} target="_blank" rel="noopener">dummyLabel</Link>

    return (
        <footer className={styles['footer']}>
            <Container maxWidth="lg">
                <div className={styles['footer-footnotes']}>
                    <Trans i18nKey="wrapper.footer.footnotes" components={[contestLink]}/>
                </div>

                <div className={styles['footer-underwritten']}>
                    {
                        i18n.resolvedLanguage === "fr" ? <ManulifeLogoFR className={styles['footer-underwritten-logo']}/> : <ManulifeLogoEN className={styles['footer-underwritten-logo']}/>
                    }
                    <Trans i18nKey="wrapper.footer.underwritten"/>
                </div>

                <div className={styles['footer-legal']}>
                <Trans i18nKey="wrapper.footer.legal" components={[privacyPolicyLink, accessibleLink]}/>
                </div>
            </Container>
        </footer>
    );
}