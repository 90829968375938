import { useContext, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Autocomplete, Dialog, TextField, Typography, Button } from "@mui/material";
import { SchoolsContext } from "../../contexts/SchoolsContext";
import styles from './SchoolSelectorModal.module.scss';
import { ReactComponent as GradCapIcon } from '../../images/icon-grad-cap.svg';
import { useNavigate } from 'react-router-dom';
import pushEventToDataLayer from '../../helpers/gtm';

export default function SchoolSelectorModal(props) {
    const { isOpen } = props;
    const { schoolsData, currentSchoolSlug, setCurrentSchoolSlug } = useContext(SchoolsContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [ hasValue, setHasValue ] = useState(false);

    const navigateToSchool = () => {
        if (currentSchoolSlug !== null) {
            const path = i18n.resolvedLanguage === "fr" ? `/${currentSchoolSlug}/fr` : `/${currentSchoolSlug}`;
            navigate(path);
        }
    }

    const onAutocomplateChange = (event, value, reason, details) => {
        setHasValue(value !== null);
        setCurrentSchoolSlug(value !== null ? value.id : null);
    };

    const onAutocompleteInputChange = (event, value, reason) => {
        setHasValue(value !== '');
    };

    const onContinueClick = () => {
        if (currentSchoolSlug !== null) {
            pushEventToDataLayer('click_school_select_continue', {
                school_slug: currentSchoolSlug
            });
        }

        navigateToSchool();
    };

    const onAutocompleteKeyDown = (event) => {
        if (event.key === 'Enter' && hasValue) {
            navigateToSchool();
        }
    };

    useEffect(() => {
        setHasValue(false);
    }, []);

    // Format the school options from schools data from context.
    const selectorOptions = [
        {
            label: t('entry.selectorPlaceholder'),
            id: null,
        }
    ];
    for (const slug in schoolsData) {
        selectorOptions.push({
            label: schoolsData[slug].label,
            id: slug
        });
    }

    return (
        <Dialog open={isOpen} className={styles['modal']} scroll="body">
            <div className={styles['modal-top']}>
                <Typography variant="modalHeader" component="h1" className={styles['modal-top-header']}>
                    <Trans i18nKey="entry.header"/>
                </Typography>
            </div>

            <div className={styles['modal-content']}>
                <GradCapIcon className={styles['modal-content-icon']}/>
                <div className={styles['modal-content-question']}>
                    <Trans i18nKey="entry.question"/>
                </div>

                <Autocomplete
                    onChange={onAutocomplateChange}
                    onInputChange={onAutocompleteInputChange}
                    onKeyDown={onAutocompleteKeyDown}
                    isOptionEqualToValue={(option, value) => option.id === value.id }
                    className={styles['modal-content-selector'] + ' ' + (hasValue ? 'has-value' : '')}
                    id="school-selector"
                    options={selectorOptions}
                    getOptionDisabled={(option) => option.id === null}
                    renderInput={(params) =>
                        <TextField {...params}
                            label={t('entry.selectorPlaceholder')}
                            InputLabelProps={{hidden: true, shrink: false, className: styles['modal-content-selector-label']}}/>
                    }
                />

                <Button
                    disabled={!hasValue}
                    onClick={onContinueClick}
                    variant="contained"
                    className={styles['modal-content-continue']}>
                        {t('entry.continue')}
                </Button>
            </div>
        </Dialog>
    );
}