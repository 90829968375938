import { Container, Button, ButtonBase, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SchoolsContext } from "../../contexts/SchoolsContext";
import { useTranslation, Trans } from 'react-i18next';
import pushEventToDataLayer from '../../helpers/gtm';
import styles from './ProductCallouts.module.scss';

export default function ProductCallouts(props) {
    const { t, i18n } = useTranslation();
    const { currentSchoolSlug, schoolsData } = useContext(SchoolsContext);

    const [applyTermLifeUrl, setApplyTermLifeUrl] = useState('');
    const [applyTerm10Url, setApplyTerm10Url] = useState('');
    const [brochureTermLifeUrl, setBrochureTermLifeUrl] = useState('');
    const [brochureTerm10Url, setBrochureTerm10Url] = useState('');

    useEffect(() => {
        if (currentSchoolSlug) {
            setApplyTermLifeUrl(schoolsData[currentSchoolSlug].applyurl.termlife[i18n.resolvedLanguage]);
            setApplyTerm10Url(schoolsData[currentSchoolSlug].applyurl.term10[i18n.resolvedLanguage]);
            setBrochureTermLifeUrl(i18n.resolvedLanguage === "fr" ? "/pdf/Alumni Term Life to 85 Insurance_Brochure_French.pdf" : "/pdf/Alumni Term Life to 85 Insurance_Brochure_English.pdf");
            setBrochureTerm10Url(i18n.resolvedLanguage === "fr" ? "/pdf/22_381453_Alumni_TermLife10_Brochure_Digital_FR_AODA_v1.pdf" : "/pdf/22_381453_Alumni_TermLife10_Brochure_Digital_EN_AODA_v1.pdf");
        }
    }, [i18n.resolvedLanguage, currentSchoolSlug]);

    return (
        <div className={styles['callouts-wrapper']} id='term-life-plans'>
            <Container maxWidth="lg">
                <div className={styles['callouts']}>
                    <div className={styles['callouts-item']}>
                        <div className={styles['callouts-item-header']}>{t('callouts.term10.header')}</div>
                        <div className={styles['callouts-item-content']}><Trans i18nKey="callouts.term10.content"/></div>
                    </div>

                    <div className={styles['callouts-item']}>
                        <div className={styles['callouts-item-header']}>{t('callouts.termLife.header')}</div>
                        <div className={styles['callouts-item-content']}><Trans i18nKey="callouts.termLife.content"/></div>
                    </div>
                </div>

                <Accordion className={styles['accordion']}>
                    <AccordionSummary id="panel-header" aria-controls="panel-content" className={styles['accordion-header']}>
                        {t('callouts.accordion.header')}
                    </AccordionSummary>
                    <AccordionDetails className={styles['accordion-content']}>

                        <div className={styles['accordion-content-column']}>
                            <div className={styles['accordion-content-column-header']}>{t('callouts.accordion.columns.term10.header')}</div>
                            <div className={styles['accordion-content-column-content']}>
                                <Trans i18nKey="callouts.accordion.columns.term10.content"/>
                            </div>
                        </div>

                        <div className={styles['accordion-content-column']}>
                            <div className={styles['accordion-content-column-header']}>{t('callouts.accordion.columns.termLife.header')}</div>
                            <div className={styles['accordion-content-column-content']}>
                                <Trans i18nKey="callouts.accordion.columns.termLife.content"/>
                            </div>
                        </div>

                    </AccordionDetails>
                </Accordion>
            </Container>
        </div>
    );
}