import { Container } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styles from './Masthead.module.scss';
import pushEventToDataLayer from '../../helpers/gtm';

export default function ApplicationCallout(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles['masthead']} id='masthead'>
            <Container maxWidth="lg" className={styles['masthead-container']}>
                <div className={styles['masthead-content']}>
                    <div className={styles['masthead-content-callout']}><Trans i18nKey="masthead.callout" /></div>
                    <h1 className={styles['masthead-content-headline']}><Trans i18nKey="masthead.headline" /></h1>
                    <div className={styles['masthead-content-subtitle']}><Trans i18nKey="masthead.subtitle" /></div>
                    <div className={styles['masthead-content-tagline']}><Trans i18nKey="masthead.tagline" /></div>
                </div>
            </Container>
        </div>
    );
}