import { Box } from '@mui/material';
import { ReactComponent as AccordionClosedIcon } from '../images/icon-accordion-closed.svg';
import { ReactComponent as AccordionExpandedIcon } from '../images/icon-accordion-expanded.svg';

export default function AccordionExpandIcon() {
    return (
        <Box sx={{
            '.expandIconWrapper, .collapsedIconWrapper': {
                display: 'flex',
                alignItems: 'center',
                'svg': {
                    width: 20,
                    height: 20
                }
            },
            '.Mui-expanded & > .collapsedIconWrapper': {
                display: 'none !important',
            },
            '.expandIconWrapper': {
                display: 'none',
            },
            '.Mui-expanded & > .expandIconWrapper': {
                display: 'flex',
            }
        }}>
            <div className="expandIconWrapper">
                <AccordionExpandedIcon/>
            </div>
            <div className="collapsedIconWrapper">
                <AccordionClosedIcon/>
            </div>
        </Box>
    )
};