import { Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SchoolsContext } from "../../contexts/SchoolsContext";
import { useTranslation, Trans } from 'react-i18next';
import getTermLifeMonthlyRate from '../../helpers/termLifeRates';
import getTerm10MonthlyRate from '../../helpers/term10Rates';
import getEquivalentCoverageAmounts from '../../helpers/coverageEquivalency';
import styles from './Calculator.module.scss';
import CalculatorSaveQuoteModal from '../CalculatorSaveQuoteModal/CalculatorSaveQuoteModal';
import CalculatorQuoteColumn from '../CalculatorQuoteColumn/CalculatorQuoteColumn';
import LoaderBackdrop from '../LoaderBackdrop';
import pushEventToDataLayer from '../../helpers/gtm';
import CalculatorTooltip from '../CalculatorTooltip/CalculatorTooltip';

export default function Calculator(props) {
    const sendEmailWS = 'https://alumnitermlifefall2024.azurewebsites.net/api/SendQuote?code=3CA9Tlil_iIhDWLR7JYtXSM6BTEUS4cR5JGVQTXd0h61AzFue-vWSg%3D%3D';
    const coverageAmounts = {
        term10: {
            min: 25000,
            max: 2000000,
            unit: 25000
        },
        termlife: {
            min: 35000,
            max: 770000,
            unit: 35000
        }
    };
    const { t, i18n } = useTranslation();
    const { currentSchoolSlug, schoolsData } = useContext(SchoolsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [quizData, setQuizData] = useState({
        gender: null,
        is_smoker: null,
        age: '',
        coverage_amount_term10: coverageAmounts.term10.min,
        coverage_amount_termlife: coverageAmounts.termlife.min,
        email: '',
    });
    const [quizDataValidity, setQuizDataValidity] = useState({
        gender: false,
        is_smoker: false,
        age: false,
        coverage_amount_term10: true, // true because default is the minimum (which is valid)
        coverage_amount_termlife: true, // true because default is the minimum (which is valid)
        email: false
    });
    const [canCalculateMonthlyCost, setCanCalculateMonthlyCost] = useState(false);
    const [canSendQuote, setCanSendQuote] = useState(false);
    const [calculatedMonthlyRateTermlife, setCalculatedMonthlyRateTermlife] = useState(0);
    const [calculatedMonthlyRateTerm10, setCalculatedMonthlyRateTerm10] = useState(0);
    const [sliderInputKeys, setSliderInputKeys] = useState({
        term10: 'key-term10',
        termlife: 'key-termlife'
    });
    const [applyTermLifeUrl, setApplyTermLifeUrl] = useState('');
    const [applyTerm10Url, setApplyTerm10Url] = useState('');
    const [brochureTermLifeUrl, setBrochureTermLifeUrl] = useState('');
    const [brochureTerm10Url, setBrochureTerm10Url] = useState('');

    const [isSaveQuoteModalOpen, setIsSaveQuoteModalOpen] = useState(false);
    const [isSendQuoteSuccess, setIsSendQuoteSuccess] = useState(false);

    useEffect(() => {
        // update apply and brochure urls
        if (currentSchoolSlug) {
            setApplyTermLifeUrl(schoolsData[currentSchoolSlug].applyurl.termlife[i18n.resolvedLanguage]);
            setApplyTerm10Url(schoolsData[currentSchoolSlug].applyurl.term10[i18n.resolvedLanguage]);
            setBrochureTermLifeUrl(i18n.resolvedLanguage === "fr" ? "/pdf/Alumni Term Life to 85 Insurance_Brochure_French.pdf" : "/pdf/Alumni Term Life to 85 Insurance_Brochure_English.pdf");
            setBrochureTerm10Url(i18n.resolvedLanguage === "fr" ? "/pdf/22_381453_Alumni_TermLife10_Brochure_Digital_FR_AODA_v1.pdf" : "/pdf/22_381453_Alumni_TermLife10_Brochure_Digital_EN_AODA_v1.pdf");
        }

        // calculate rate
        if (quizDataValidity.gender && quizDataValidity.is_smoker && quizDataValidity.age) {
            if (quizDataValidity.coverage_amount_term10 && quizDataValidity.coverage_amount_termlife) {
                let term10Rate = getTerm10MonthlyRate(quizData.age, quizData.gender, quizData.is_smoker, quizData.coverage_amount_term10);
                if (term10Rate !== null) {
                    setCalculatedMonthlyRateTerm10(term10Rate);
                }

                let termlifeRate = getTermLifeMonthlyRate(quizData.age, quizData.gender, quizData.is_smoker, quizData.coverage_amount_termlife);
                if (termlifeRate !== null) {
                    setCalculatedMonthlyRateTermlife(termlifeRate);
                }
            }
        }

        // reset send quote state
        setIsSendQuoteSuccess(false);

    }, [quizData, i18n.resolvedLanguage, currentSchoolSlug]);

    // update state variables that determine various validity variants
    useEffect(() => {
        // monthly cost can be calculated when both personal details and coverage amount are valid
        setCanCalculateMonthlyCost(quizDataValidity.gender && quizDataValidity.age && quizDataValidity.is_smoker && quizDataValidity.coverage_amount_term10 && quizDataValidity.coverage_amount_termlife);

        // quote can be sent when all fields (personal details, coverage amount, EMAIL) are valid
        setCanSendQuote(quizDataValidity.gender && quizDataValidity.age && quizDataValidity.is_smoker && quizDataValidity.coverage_amount_term10 && quizDataValidity.coverage_amount_termlife && quizDataValidity.email);
    }, [quizDataValidity]);

    const handleQuizInputChange = (event) => {
        let validUpdated = {};
        let dataUpdated = {};

        // check validity
        let isValid = false;

        switch (event.target.name) {
            case 'age':
                isValid = event.target.value >= 18 && event.target.value <= 70;
                break;
            default:
                isValid = event.target.value !== null && event.target.value !== '';
                break;
        }

        validUpdated[event.target.name] = isValid;
        dataUpdated[event.target.name] = event.target.value;

        // update validity state object
        setQuizDataValidity({
            ...quizDataValidity,
            ...validUpdated
        });

        // update state object
        setQuizData({
            ...quizData,
            ...dataUpdated
        });
    };

    const getAdjustedCoverageAmount = (inputValue, planSlug) => {
        const planMin = coverageAmounts[planSlug].min;
        const planMax = coverageAmounts[planSlug].max;
        let newCoverageValue = inputValue === '' ? 0 : Number(inputValue.replace(/\D/g,''));

        // if the entered value is not within plan bounds, adjust it
        // if the entered valus is not divisible by allowed step (coverage unit) value, adjust it
        if (newCoverageValue < planMin) {
            newCoverageValue = planMin;
        } else if (newCoverageValue > planMax) {
            newCoverageValue = planMax;
        } else if (newCoverageValue % coverageAmounts[planSlug].unit !== 0) {
            newCoverageValue = Math.floor(newCoverageValue / coverageAmounts[planSlug].unit) * coverageAmounts[planSlug].unit;
        }

        setQuizDataValidity({
            ...quizDataValidity,
            ['coverage_amount_' + planSlug]: true
        });

        return newCoverageValue;
    };

    const handleSliderInputChange = (event, planSlug) => {
        if ((event.type === 'keyup' && event.key === 'Enter') || event.type === 'blur') {
            // Make sure that coverage value is within the range and valid (can be divided to steps). If not, adjust to the closest valid value.
            let newCoverageValue = getAdjustedCoverageAmount(event.target.value, planSlug);

            // "hack" to force update the input value even if the quizData state is not changed yet.
            setSliderInputKeys({
                ...sliderInputKeys,
                [planSlug]: Date.now()
            });

            // get equivalent coverage value
            const coverageEquivalency = getEquivalentCoverageAmounts(planSlug, newCoverageValue);

            // update state object
            setQuizData({
                ...quizData,
                coverage_amount_term10: coverageEquivalency.term10,
                coverage_amount_termlife: coverageEquivalency.termlife
            });

            setQuizDataValidity({
                ...quizDataValidity,
                coverage_amount_term10: true,
                coverage_amount_termlife: true
            });
        }
    };

    const handleSliderChange = (event, planSlug) => {
        const sliderCoverageValue = Number(event.target.value);

        // get equivalent coverage value
        const coverageEquivalency = getEquivalentCoverageAmounts(planSlug, sliderCoverageValue);

        // update state object
        setQuizData({
            ...quizData,
            coverage_amount_term10: coverageEquivalency.term10,
            coverage_amount_termlife: coverageEquivalency.termlife
        });

        setQuizDataValidity({
            ...quizDataValidity,
            coverage_amount_term10: true,
            coverage_amount_termlife: true
        });
    };

    const handleSaveQuoteModalOpen = () => {
        pushEventToDataLayer('calculator_savequote_open');
        setIsSaveQuoteModalOpen(true);
    };

    const handleSaveQuoteModalClose = (event) => {
        setIsSaveQuoteModalOpen(false);
    };

    const handleEmailInputChange = (event) => {
        // check validity
        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        setQuizDataValidity({
            ...quizDataValidity,
            email: re.test(event.target.value)
        });

        // update state object
        setQuizData({
            ...quizData,
            email: event.target.value
        });
    };

    const handleEmailInputKeyUp = (event) => {
        if (event.key === 'Enter' && quizDataValidity.email === true) {
            submitForm();
        }
    };

    const submitForm = async () => {
        if (canSendQuote) {
            setIsLoading(true);

            const payload = {
                email: quizData.email,
                age: quizData.age,
                gender: quizData.gender,
                is_smoker: quizData.is_smoker,
                coverage_amount: quizData.coverage_amount_term10, // we're showing two quotes at the same time, but need to send one to the web service. Sending term10 because it will work better with equivalency calculation (more range).
                is_contest: true,
                language: i18n.resolvedLanguage === 'fr' ? 'fr-CA' : 'en-CA',
                origin: 'T10',
                school: currentSchoolSlug
            };

            try {
                const response = await fetch(sendEmailWS, {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                if (response.ok) {
                    pushEventToDataLayer('calculator_savequote_submit');
                    setIsSendQuoteSuccess(true);
                }
                else {
                    alert('There was an error on form submission.');
                    console.log(response);
                }
            } catch (error) {
                alert('There was an error on form submission.');
                console.log(response);
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            alert('There was an error in the form data.');
            console.log('Error in form data', quizData);
        }

    };

    return (
        <div className={styles['calculator-wrapper']} id="term-life-calculator">
            <Container maxWidth="lg">
                <div className={styles['calculator']}>
                    <div className={styles['calculator-top']}>
                        <div className={styles['calculator-top-header']}><Trans i18nKey="calculatorQuiz.header"></Trans></div>
                        <div className={styles['calculator-top-subheader']}><Trans i18nKey="calculatorQuiz.subheader"></Trans></div>
                    </div>

                    <div className={styles['calculator-questions']}>
                        <div className={styles['calculator-questions-column']}>
                            <FormControl className={styles['calculator-questions-column-control']}>
                                <FormLabel id="question-sex" className={styles['calculator-questions-column-control-label']}>
                                    <Trans i18nKey={`calculatorQuiz.questions.sex.label`}/>
                                    <CalculatorTooltip tooltipTextKey={'calculatorQuiz.questions.sex.tooltip'}/>
                                </FormLabel>
                                <RadioGroup
                                    onChange={handleQuizInputChange}
                                    value={quizData[t(`calculatorQuiz.questions.sex.param`)]}
                                    aria-labelledby="question-sex"
                                    name={t(`calculatorQuiz.questions.sex.param`)}
                                    className={styles['calculator-questions-column-control-radio']}>
                                    <FormControlLabel value="male" control={<Radio color="secondary"/>} label={t(`calculatorQuiz.questions.sex.optionMale`)}/>
                                    <FormControlLabel value="female" control={<Radio color="secondary"/>} label={t(`calculatorQuiz.questions.sex.optionFemale`)}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className={styles['calculator-questions-column']}>
                            <FormControl className={styles['calculator-questions-column-control']}>
                                <FormLabel id="question-smoker" className={styles['calculator-questions-column-control-label']}>
                                    <Trans i18nKey={`calculatorQuiz.questions.smoker.label`}/>
                                    <CalculatorTooltip tooltipTextKey={'calculatorQuiz.questions.smoker.tooltip'}/>
                                </FormLabel>
                                <RadioGroup
                                    onChange={handleQuizInputChange}
                                    value={quizData[t(`calculatorQuiz.questions.smoker.param`)]}
                                    aria-labelledby="question-smoker"
                                    name={t(`calculatorQuiz.questions.smoker.param`)}
                                    className={styles['calculator-questions-column-control-radio']}>
                                    <FormControlLabel value="true" control={<Radio color="secondary"/>} label={t(`calculatorQuiz.questions.smoker.optionYes`)}/>
                                    <FormControlLabel value="false" control={<Radio color="secondary"/>} label={t(`calculatorQuiz.questions.smoker.optionNo`)}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className={styles['calculator-questions-column']}>
                            <FormControl className={styles['calculator-questions-column-control']}>
                                <FormLabel id="question-age" className={styles['calculator-questions-column-control-label']}>
                                    <Trans i18nKey={`calculatorQuiz.questions.age.label`}/>
                                    <CalculatorTooltip tooltipTextKey={'calculatorQuiz.questions.age.tooltip'}/>
                                </FormLabel>
                                <TextField
                                    onChange={handleQuizInputChange}
                                    value={quizData[t(`calculatorQuiz.questions.age.param`)]}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    error={quizData[t(`calculatorQuiz.questions.age.param`)] === '' ? false : !quizDataValidity[t(`calculatorQuiz.questions.age.param`)]}
                                    helperText={quizData[t(`calculatorQuiz.questions.age.param`)] === '' ? '' : (quizDataValidity[t(`calculatorQuiz.questions.age.param`)] ? '' : t(`calculatorQuiz.questions.age.error`))}
                                    aria-labelledby={`question-age`}
                                    name={t(`calculatorQuiz.questions.age.param`)}
                                    placeholder={t(`calculatorQuiz.questions.age.placeholder`)}
                                    className={styles['calculator-questions-column-control-textfield'] + ' ' + (quizData[t(`calculatorQuiz.questions.age.param`)].length > 0 && quizDataValidity[t(`calculatorQuiz.questions.age.param`)] ? 'has-valid-value' : '')}/>
                            </FormControl>
                        </div>
                    </div>

                    <div className={styles['calculator-quotes']}>
                        <CalculatorQuoteColumn
                            planSlug="term10"
                            sliderInputKey={sliderInputKeys.term10}
                            onSliderInputChange={e => handleSliderInputChange(e, 'term10')}
                            coverageAmount={quizData.coverage_amount_term10}
                            onSliderChange={e => handleSliderChange(e, 'term10')}
                            coverageMin={coverageAmounts.term10.min}
                            coverageMax={coverageAmounts.term10.max}
                            coverageStep={coverageAmounts.term10.unit}
                            calculatedMonthlyRate={calculatedMonthlyRateTerm10}
                            applyUrl={applyTerm10Url}
                            brochureUrl={brochureTerm10Url}
                            canCalculateMonthlyCost={canCalculateMonthlyCost}
                            />
                        <CalculatorQuoteColumn
                            planSlug="termlife"
                            sliderInputKey={sliderInputKeys.termlife}
                            onSliderInputChange={e => handleSliderInputChange(e, 'termlife')}
                            coverageAmount={quizData.coverage_amount_termlife}
                            onSliderChange={e => handleSliderChange(e, 'termlife')}
                            coverageMin={coverageAmounts.termlife.min}
                            coverageMax={coverageAmounts.termlife.max}
                            coverageStep={coverageAmounts.termlife.unit}
                            calculatedMonthlyRate={calculatedMonthlyRateTermlife}
                            applyUrl={applyTermLifeUrl}
                            brochureUrl={brochureTermLifeUrl}
                            canCalculateMonthlyCost={canCalculateMonthlyCost}
                            />
                    </div>

                    <div className={styles['calculator-bottom']}>
                        <div className={styles['calculator-bottom-title']}><Trans i18nKey={`calculatorQuiz.quotes.saveQuoteHeader`}/></div>
                        <Button onClick={(event) => handleSaveQuoteModalOpen(event)} disabled={!canCalculateMonthlyCost} className={styles['calculator-bottom-savequote']} variant="outlined"><Trans i18nKey="calculatorQuiz.quotes.saveQuoteButton"/></Button>
                    </div>

                    <CalculatorSaveQuoteModal
                        open={isSaveQuoteModalOpen}
                        handleClose={(event) => handleSaveQuoteModalClose(event)}
                        isSuccess={isSendQuoteSuccess}
                        emailInputValue={quizData.email}
                        emailInputIsValid={quizDataValidity.email}
                        onEmailInputChange={(event) => handleEmailInputChange(event)}
                        onEmailInputKeyUp={(event) => handleEmailInputKeyUp(event)}
                        onSubmitButtonClick={() => submitForm()}/>

                    { isLoading && <LoaderBackdrop/>}
                </div>
            </Container>
        </div>
    );
}