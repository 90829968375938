// Usually the client sends the equivalency chart as a spreadsheet with two columns.
// Adapt =CONCATENATE("if (sourceCoverageAmount === ", A18, ") return {termlife: ", A18, ", term10: ", B18, "};")
// For both Term Life and Term 10

/**
 * Get equivalent coverage amounts based on the "source" plan name and coverage amount.
 * @param {string} planName The "source" plan name - either 'term10' or 'termlife'
 * @param {number} sourceCoverageAmount The "source" coverage amount
 * @return {Object} {termlife: value, term10: value}. Null if not found.
 */
export default function getEquivalentCoverageAmounts(planName, sourceCoverageAmount) {
    if (planName === 'termlife') {
        if (sourceCoverageAmount === 35000) return {termlife: 35000, term10: 50000};
        if (sourceCoverageAmount === 70000) return {termlife: 70000, term10: 75000};
        if (sourceCoverageAmount === 105000) return {termlife: 105000, term10: 100000};
        if (sourceCoverageAmount === 140000) return {termlife: 140000, term10: 150000};
        if (sourceCoverageAmount === 175000) return {termlife: 175000, term10: 175000};
        if (sourceCoverageAmount === 210000) return {termlife: 210000, term10: 200000};
        if (sourceCoverageAmount === 245000) return {termlife: 245000, term10: 250000};
        if (sourceCoverageAmount === 280000) return {termlife: 280000, term10: 275000};
        if (sourceCoverageAmount === 315000) return {termlife: 315000, term10: 325000};
        if (sourceCoverageAmount === 350000) return {termlife: 350000, term10: 350000};
        if (sourceCoverageAmount === 385000) return {termlife: 385000, term10: 375000};
        if (sourceCoverageAmount === 420000) return {termlife: 420000, term10: 425000};
        if (sourceCoverageAmount === 455000) return {termlife: 455000, term10: 450000};
        if (sourceCoverageAmount === 490000) return {termlife: 490000, term10: 500000};
        if (sourceCoverageAmount === 525000) return {termlife: 525000, term10: 525000};
        if (sourceCoverageAmount === 560000) return {termlife: 560000, term10: 550000};
        if (sourceCoverageAmount === 595000) return {termlife: 595000, term10: 600000};
        if (sourceCoverageAmount === 630000) return {termlife: 630000, term10: 625000};
        if (sourceCoverageAmount === 665000) return {termlife: 665000, term10: 675000};
        if (sourceCoverageAmount === 700000) return {termlife: 700000, term10: 700000};
        if (sourceCoverageAmount === 735000) return {termlife: 735000, term10: 725000};
        if (sourceCoverageAmount === 770000) return {termlife: 770000, term10: 775000};
        return null;
    } else if (planName === 'term10') {
        if (sourceCoverageAmount === 25000) return {termlife: 35000, term10: 25000};
        if (sourceCoverageAmount === 50000) return {termlife: 35000, term10: 50000};
        if (sourceCoverageAmount === 75000) return {termlife: 70000, term10: 75000};
        if (sourceCoverageAmount === 100000) return {termlife: 105000, term10: 100000};
        if (sourceCoverageAmount === 125000) return {termlife: 105000, term10: 125000};
        if (sourceCoverageAmount === 150000) return {termlife: 140000, term10: 150000};
        if (sourceCoverageAmount === 175000) return {termlife: 175000, term10: 175000};
        if (sourceCoverageAmount === 200000) return {termlife: 210000, term10: 200000};
        if (sourceCoverageAmount === 225000) return {termlife: 210000, term10: 225000};
        if (sourceCoverageAmount === 250000) return {termlife: 245000, term10: 250000};
        if (sourceCoverageAmount === 275000) return {termlife: 280000, term10: 275000};
        if (sourceCoverageAmount === 300000) return {termlife: 280000, term10: 300000};
        if (sourceCoverageAmount === 325000) return {termlife: 315000, term10: 325000};
        if (sourceCoverageAmount === 350000) return {termlife: 350000, term10: 350000};
        if (sourceCoverageAmount === 375000) return {termlife: 385000, term10: 375000};
        if (sourceCoverageAmount === 400000) return {termlife: 420000, term10: 400000};
        if (sourceCoverageAmount === 425000) return {termlife: 420000, term10: 425000};
        if (sourceCoverageAmount === 450000) return {termlife: 455000, term10: 450000};
        if (sourceCoverageAmount === 475000) return {termlife: 455000, term10: 475000};
        if (sourceCoverageAmount === 500000) return {termlife: 490000, term10: 500000};
        if (sourceCoverageAmount === 525000) return {termlife: 525000, term10: 525000};
        if (sourceCoverageAmount === 550000) return {termlife: 560000, term10: 550000};
        if (sourceCoverageAmount === 575000) return {termlife: 560000, term10: 575000};
        if (sourceCoverageAmount === 600000) return {termlife: 595000, term10: 600000};
        if (sourceCoverageAmount === 625000) return {termlife: 630000, term10: 625000};
        if (sourceCoverageAmount === 650000) return {termlife: 630000, term10: 650000};
        if (sourceCoverageAmount === 675000) return {termlife: 665000, term10: 675000};
        if (sourceCoverageAmount === 700000) return {termlife: 700000, term10: 700000};
        if (sourceCoverageAmount === 725000) return {termlife: 735000, term10: 725000};
        if (sourceCoverageAmount === 750000) return {termlife: 735000, term10: 750000};
        if (sourceCoverageAmount > 750000) return {termlife: 770000, term10: sourceCoverageAmount};
        return null;
    } else {
        return null;
    }
}