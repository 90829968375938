// AGE BANDS: array indexes
// 0: 18 to 30
// all following indexes are one age per index, starting from 31 to 84

// COVERAGE BANDS: object keys
// c25to75: 25,000 to 75,000
// c100to250: 100,000 to 250,000
// c275to2000: 275,000 to 2,000,000

const term10Rates = {
    c25to75: {
        male: {
            nonsmoker: [2.21, 2.25, 2.25, 2.3, 2.34, 2.34, 2.38, 2.42, 2.55, 2.72, 2.85, 3.02, 3.15, 3.49, 3.78, 4.12, 4.42, 4.76, 5.36, 5.95, 6.59, 7.18, 7.78, 8.67, 9.52, 10.37, 11.22, 12.11, 13.56, 15.13, 16.92, 18.91, 21.12, 24.48, 27.58, 30.94, 34.68, 38.42, 42.71, 47.01, 51.3, 54.87, 58.74, 62.86, 67.24, 71.95, 76.97, 82.37, 88.1, 94.27, 101.19, 109.23, 118.75, 130.18, 143.82],
            smoker: [3.4, 3.61, 3.83, 4.12, 4.46, 4.76, 5.1, 5.4, 5.95, 6.46, 6.97, 7.48, 7.99, 8.97, 9.95, 10.92, 11.9, 12.88, 14.15, 15.43, 16.7, 17.98, 19.25, 21.76, 24.27, 26.73, 29.24, 31.75, 35.19, 39.02, 43.27, 47.98, 53.17, 58.06, 63.41, 69.23, 75.61, 82.58, 87.76, 92.95, 98.13, 105.02, 112.37, 120.23, 128.69, 137.7, 147.31, 157.63, 168.64, 180.46, 193.76, 209.27, 227.76, 249.94, 276.51]
        },
        female: {
            nonsmoker: [1.7, 1.7, 1.7, 1.7, 1.74, 1.79, 1.83, 1.87, 2, 2.13, 2.25, 2.38, 2.51, 2.72, 2.89, 3.1, 3.32, 3.53, 3.87, 4.21, 4.55, 4.89, 5.23, 5.95, 6.67, 7.31, 8.03, 8.8, 9.65, 10.58, 11.6, 12.71, 13.94, 15.77, 17.81, 20.1, 22.74, 25.67, 28.77, 32.26, 36.17, 38.72, 41.44, 44.33, 47.43, 50.75, 54.32, 58.14, 62.18, 66.56, 71.49, 77.27, 84.15, 92.4, 102.3],
            smoker: [2.51, 2.68, 2.85, 3.02, 3.23, 3.44, 3.61, 3.83, 4.17, 4.46, 4.8, 5.14, 5.48, 6.04, 6.55, 7.1, 7.65, 8.2, 8.84, 9.52, 10.16, 10.8, 11.48, 13.09, 14.75, 16.36, 17.94, 19.64, 21.42, 23.42, 25.54, 27.88, 30.43, 33.75, 36.64, 39.87, 43.61, 47.3, 51.55, 55.76, 60.01, 64.22, 68.72, 73.53, 78.67, 84.15, 90.06, 96.35, 103.11, 110.29, 118.41, 127.84, 139.1, 152.53, 168.64]
        }
    },
    c100to250: {
        male: {
            nonsmoker: [1.7, 1.74, 1.74, 1.79, 1.79, 1.83, 1.83, 1.87, 1.96, 2.08, 2.21, 2.34, 2.42, 2.68, 2.93, 3.19, 3.4, 3.66, 4.12, 4.59, 5.06, 5.53, 5.99, 6.67, 7.31, 7.99, 8.63, 9.35, 10.41, 11.65, 13.01, 14.54, 16.24, 18.83, 21.21, 26.6, 29.83, 33.01, 36.72, 40.42, 44.13, 47.22, 50.49, 54.06, 57.81, 61.85, 66.17, 70.78, 75.76, 81.08, 86.97, 93.91, 102.08, 111.91, 123.64],
            smoker: [2.64, 2.76, 2.93, 3.19, 3.44, 3.7, 3.91, 4.17, 4.59, 4.97, 5.36, 5.78, 6.16, 6.93, 7.65, 8.42, 9.14, 9.9, 10.88, 11.86, 12.84, 13.86, 14.83, 16.75, 18.66, 20.57, 22.48, 24.44, 27.07, 30.01, 33.28, 36.89, 40.93, 44.67, 48.79, 59.52, 65.03, 70.97, 75.43, 79.9, 84.41, 90.3, 96.62, 103.41, 110.63, 118.37, 126.64, 135.52, 145.02, 155.14, 166.58, 179.93, 195.84, 214.89, 237.69]
        },
        female: {
            nonsmoker: [1.32, 1.32, 1.32, 1.32, 1.36, 1.36, 1.4, 1.45, 1.53, 1.62, 1.74, 1.83, 1.91, 2.08, 2.25, 2.42, 2.55, 2.72, 2.98, 3.23, 3.53, 3.78, 4.04, 4.59, 5.14, 5.65, 6.16, 6.76, 7.44, 8.16, 8.93, 9.78, 10.71, 12.11, 13.69, 15.47, 17.47, 19.76, 22.14, 24.82, 27.84, 29.79, 31.88, 34.13, 36.51, 39.02, 41.78, 44.71, 47.81, 51.17, 55, 59.46, 64.73, 71.06, 78.67],
            smoker: [1.96, 2.08, 2.17, 2.34, 2.51, 2.64, 2.81, 2.93, 3.19, 3.44, 3.7, 3.95, 4.21, 4.63, 5.06, 5.48, 5.91, 6.33, 6.8, 7.31, 7.82, 8.33, 8.84, 10.07, 11.35, 12.58, 13.81, 15.13, 16.49, 18.02, 19.64, 21.46, 23.42, 25.97, 28.18, 30.69, 33.53, 36.38, 39.65, 42.93, 46.16, 49.43, 52.87, 56.57, 60.52, 64.73, 69.28, 74.12, 79.31, 84.87, 91.08, 98.35, 106.97, 117.34, 129.71]
        }
    },
    c275to2000: {
        male: {
            nonsmoker: [1.53, 1.57, 1.57, 1.62, 1.62, 1.62, 1.66, 1.66, 1.79, 1.87, 2, 2.08, 2.21, 2.42, 2.64, 2.85, 3.06, 3.32, 3.7, 4.12, 4.55, 4.97, 5.4, 5.99, 6.59, 7.18, 7.78, 8.42, 9.39, 10.5, 11.73, 13.09, 14.62, 16.96, 19.13, 24.7, 27.69, 30.67, 34.1, 37.53, 40.96, 43.81, 46.89, 50.18, 53.66, 57.43, 61.45, 65.76, 70.36, 75.26, 80.75, 87.17, 94.82, 103.93, 114.81],
            smoker: [2.34, 2.51, 2.64, 2.85, 3.1, 3.32, 3.53, 3.74, 4.12, 4.46, 4.85, 5.19, 5.57, 6.21, 6.89, 7.57, 8.25, 8.93, 9.78, 10.67, 11.56, 12.45, 13.35, 15.09, 16.79, 18.53, 20.23, 21.97, 24.35, 27.03, 29.96, 33.19, 36.81, 40.21, 43.9, 55.27, 60.37, 65.91, 70.07, 74.19, 78.35, 83.84, 89.72, 95.99, 102.7, 109.91, 117.6, 125.83, 134.6, 144.06, 154.64, 167.04, 181.84, 199.53, 220.7]
        },
        female: {
            nonsmoker: [1.19, 1.19, 1.19, 1.19, 1.23, 1.23, 1.28, 1.28, 1.36, 1.49, 1.57, 1.66, 1.74, 1.87, 2.04, 2.17, 2.3, 2.47, 2.68, 2.93, 3.15, 3.4, 3.61, 4.12, 4.63, 5.06, 5.57, 6.08, 6.67, 7.31, 8.03, 8.8, 9.65, 10.92, 12.33, 13.94, 15.73, 17.77, 19.93, 22.36, 25.08, 26.82, 28.69, 30.69, 32.85, 35.15, 37.61, 40.25, 43.05, 46.07, 49.51, 53.51, 58.27, 63.96, 70.81],
            smoker: [1.74, 1.87, 1.96, 2.13, 2.25, 2.38, 2.51, 2.64, 2.89, 3.1, 3.32, 3.57, 3.78, 4.17, 4.55, 4.93, 5.31, 5.7, 6.12, 6.59, 7.06, 7.48, 7.95, 9.05, 10.2, 11.35, 12.45, 13.6, 14.83, 16.19, 17.68, 19.3, 21.08, 23.38, 25.37, 27.58, 30.18, 32.77, 35.7, 38.63, 41.57, 44.46, 47.6, 50.92, 54.49, 58.27, 62.35, 66.73, 71.4, 76.37, 81.98, 88.53, 96.31, 105.61, 116.75]
        }
    }
};

function getAgeIndex(age) {
    if (age >= 18 && age <= 30) {
        return 0;
    } else if (age >= 31 && age <= 84) {
        return age - 30;
    }

    return null;
}

export default function getTerm10MonthlyRate(age, gender, isSmoker, coverageAmount) {
    if (age === '' || gender === null || (gender !== 'female' && gender !== 'male') || isSmoker === null || coverageAmount <= 0 || coverageAmount % 25000 !== 0) return null;

    const ageIndex = getAgeIndex(parseInt(age, 10));
    if (ageIndex === null) return null;

    let coverageBand = null;
    if (coverageAmount >= 25000 && coverageAmount <= 75000) {
        coverageBand = 'c25to75';
    } else if (coverageAmount >= 100000 && coverageAmount <= 250000) {
        coverageBand = 'c100to250';
    } else if (coverageAmount >= 275000 && coverageAmount <= 2000000) {
        coverageBand = 'c275to2000';
    }
    if (coverageBand === null) return null;

    const smokerBand = isSmoker === 'true' ? 'smoker' : 'nonsmoker';
    const policyFee = 4; // added to the total monthly premium

    return (coverageAmount / 25000) * term10Rates[coverageBand][gender][smokerBand][ageIndex] + policyFee;
}