import { Container, Box } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styles from './ApplicationCallout.module.scss';
import ApplicationCalloutImg from '../../images/application-callout-upd.png';
import IconDownload from '../../images/icon_download.svg';
import pushEventToDataLayer from '../../helpers/gtm';

export default function ApplicationCallout(props) {
    const { t, i18n } = useTranslation();

    const termLifeLink = i18n.resolvedLanguage === "fr" ? "/pdf/Alumni Term Life to 85 - Application - 2024 - French.pdf" : "/pdf/Alumni Term Life to 85 - Application - 2024 - English.pdf";
    const term10Link = i18n.resolvedLanguage === "fr" ? "/pdf/Alumni Term 10 - Application - 2024 - French.pdf" : "/pdf/Alumni Term 10 - Application - 2024 - English.pdf";

    return (
        <div className={styles['application-callout-wrapper']}>
            <Container maxWidth="lg">
                <div className={styles['application-callout']}>
                    <img src={ApplicationCalloutImg} className={styles['application-callout-img']} role="presentation"/>
                    <div className={styles['application-callout-content']}>
                        <div className={styles['application-callout-content-header']}>
                            <Trans i18nKey="applicationCallout.header" />
                        </div>
                        <ol>
                            <li><Trans i18nKey="applicationCallout.phone" /></li>
                            <li>{t('applicationCallout.downloadApplication')}</li>
                        </ol>
                        <div className={styles['application-callout-content-pdf-links']}>
                            <a href={term10Link} target="_blank" rel="noopener" data-gtm-link-event-name="click_application_download_term10">
                                <img src={IconDownload} role="presentation"/>
                                {t('applicationCallout.downloadTerm10')}
                            </a>
                            <a href={termLifeLink} target="_blank" rel="noopener" data-gtm-link-event-name="click_application_download_termlife">
                                <img src={IconDownload} role="presentation"/>
                                {t('applicationCallout.downloadTermLife')}
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}